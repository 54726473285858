import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import { FaMapLocationDot } from "react-icons/fa6";
// import { MdMail } from "react-icons/md";
// import { FaPhoneAlt } from "react-icons/fa";
import WhatsAppButton from './Whatsapp';
import { useNavigate } from 'react-router-dom';
const Footer = () => {

  const navigate = useNavigate();
  const handlenavigate = () => {
    navigate('/products');
  }
  return (
    <>
      <div className='fixed point2 blink' onClick={handlenavigate}>
        <img src={require('../../assets/images/pricetag.png')} className='img-fluid priceicn2 float-right' alt='special price' />
      </div>
      <div className='fixed point'>
        <WhatsAppButton name="Deepa Crackers" phoneNumber="919486720694" />
      </div>
      <a href="https://www.facebook.com/Deepacrackers?mibextid=ZbWKwL">
        <div className="fixed point3" style={{ cursor: 'pointer' }}>
          <img src={require('../../assets/images/fb.png')} className='img-fluid priceicn float-left' alt='special price' />
        </div>
      </a>

      <a href="https://www.instagram.com/deepacrackers111?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
        <div className="fixed point4" style={{ cursor: 'pointer' }}>
          <img src={require('../../assets/images/inst.png')} className='img-fluid priceicn float-left' alt='special price' />
        </div>
      </a>
      <>
        <div className='footer-bg foot-pad bg-overlay'>
          <Container>
            <Row>
              <Col lg='12' className='border-top border-bottom py-4'>
                <Row className='z'>
                  <Col lg='12' className="align-self-center  py-3">
                    <div className='ft-logo mx-auto'>
                      <img src={require('../../assets/images/storelogo.png')} className='img-fluid' alt='product name' />
                    </div>
                  </Col>
                  {/* <Col lg='4' className="align-self-center py-3">
                    <div className='d-flex align-items-center justify-content-center'>
                      <div className='ft-icon'>
                          <FaMapLocationDot />
                      </div>
                      <div className='ft-details'>
                          4/1434-2,Thayilpatti Sattur Main Road<br/>
                          sivakasi - 626128. 
                      </div>
                    </div>
                  </Col>
                  <Col lg='4' className="align-self-center py-3">
                  <div className='d-flex align-items-center justify-content-center'>
                      <div className='ft-icon'>
                        <MdMail />
                      </div>
                      <div className='ft-details'>
                          Email : deepacrackers@gmail.com
                      </div>
                    </div>
                  </Col>
                  <Col lg='4' className="align-self-center py-3">
                    <div className='d-flex align-items-center justify-content-center'>
                      <div className='ft-icon'>
                         <FaPhoneAlt />
                      </div>
                      <div className='ft-details'>
                        <div>+91 94867 20694</div>
                        <div>+91 97877 80694</div>
                        <div>+91 88389 99319</div>
                      </div>
                    </div>
                  </Col> */}
                </Row>
              </Col>
              <Col lg='12' className='py-3 mx-auto'>
                <ul className='list-unstyled d-lg-flex justify-content-center d-xs-block d-sm-block text-white z'>
                  <li className='mx-3'><Link to='/'>Home</Link></li>
                  <li className='mx-3'><Link to='/about'>About</Link></li>
                  <li className='mx-3'><Link to='/products'>Products</Link></li>
                  <li className='mx-3'><Link to='/safetytips'>Safety Tips</Link></li>
                  <li className='mx-3'><Link to='/contactus'>Contact Us</Link></li>
                </ul>
              </Col>
              <Col lg='12' className='py-3 align-self-center'>
                <div className='rules text-white z '>
                  As per 2018 supreme court order, online sale of firecrackers are not permitted! We value our customers and at the same time, respect jurisdiction. We request you to add your products to the cart and submit the required crackers through the enquiry button. We will contact you within 24 hrs and confirm the order through WhatsApp or phone call. Please add and submit your enquiries and enjoy your Diwali with Deepa Crackers. Our License No.----. Deepa Crackers as a company following 100% legal & statutory compliances and all our shops, go-downs are maintained as per the explosive acts. We send the parcels through registered and legal transport service providers as like every other major companies in Sivakasi is doing so.
                </div>
              </Col>

            </Row>
          </Container>
        </div>
      </>
      <>
        <div className='mark-bg'>
          <Container>
            <Row>
              <Col lg='12' className='py-3'>
                <div className='text-center regular'> Copyright © 2024,. Deepa Crackers. All rights reserved </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    </>
  )
}

export default Footer