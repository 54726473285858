import React from 'react'
import { Container,Nav,Navbar,Row,Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import Marquee from "react-fast-marquee";
import { CiLocationArrow1 } from "react-icons/ci";
const AppBar = () => {
  return (
    <>
    <>
    <div className='mark-bg'>
      <div className='d-lg-block d-none'>
        <Container>
              <Row className='py-2'>
              <Col lg="4" xs="4" sm="4" className='align-self-center d-lg-block d-none'>
                <ul class="top-info fullpad">
                    <li>
                        <CiLocationArrow1 />
                        <p>4/1434-2,Thayilpatti Sattur Main Road,Sivakasi.</p>
                    </li>
                  </ul>
              </Col>
                <Col lg='8'>
                  <Marquee pauseOnHover={true}  direction="left" speed={50} delay={9}>
                    |  Whatsapp : +91 94867 20694 | Alter No : +91 97877 80694 | Alter No : +91 88389 99319 | Email :  deepatradersdeepacrackers@gmail.com
                  </Marquee>
                </Col>
              </Row>
          </Container>
      </div>
      <div className='d-lg-none d-block py-3'>
          <Container>
            <Row>
              <Col xs="4" sm="4">
              <a href="https://wa.me/919486720694?text=Hello deepa crackers">
                <div className='mob-icon mx-auto'>
                    <img src={require('../../assets/images/whatsappicon.webp')} className='img-fluid' alt='product name' />
                  </div>
              </a>
                  
              </Col>
              <Col xs="4" sm="4">
              <a href='tel:+919486720694'>
                <div className='mob-icon mx-auto'>
                    <img src={require('../../assets/images/callicon.webp')} className='img-fluid' alt='product name' />
                  </div> 
              </a>
                 
              </Col>
              <Col xs="4" sm="4">
              <a href='https://maps.app.goo.gl/mNvUHEfso5LkuMZ39' alt="">
                <div className='mob-icon mx-auto'>
                    <img src={require('../../assets/images/googlelocation.png')} className='img-fluid' alt='product name' />
                  </div> 
              </a>
               
              </Col>
            </Row>
          </Container>
      </div>
    </div>
    </>
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">
            <div className='logo'>
              <img src={require('../../assets/images/storelogo.png')} className='img-fluid' alt='product name' />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto text-center">
              <Nav.Link as={NavLink} exact to={"/"} >Home</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/about"} >About</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/products"} >Products</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/safetytips"} >Safety Tips</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/contact"} >Contact</Nav.Link>
              {/* <Nav.Link as={NavLink} exact to={"/safetytips"} activeClassName="active">Safety Tips</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/contactus"} activeClassName="active">Contact Us</Nav.Link> */}
            </Nav>
            {/* <Nav.Link as={NavLink} exact to={"/products"} activeClassName="active" className='text-center estimate'>Estimate Now !!!</Nav.Link> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>

    </>
  )
}

export default AppBar